.amymarkdown {
    margin: 0;
}
.amymarkdownLeft {
    text-align: left;
}

.amymarkdownRight {
    text-align: right;
}

.amymarkdown .katex {
    font-size: 1.09em;
}
.amymarkdown .katex-display {
    margin: 0;
}

.amymarkdown .katex-mathml {
    position: fixed !important;
}

.amymarkdown p {
    margin: 12px 0;
}

.amymarkdown ol {
    margin-block-start: 432;
}

.amymarkdown ul {
    list-style: disc;
    margin-block-start: 20px;
    padding-left: 40px;
}

.amymarkdown td {
    padding: 4px 7px;
    font-weight: normal;
    border: 1px solid #ddd;
}

.amymarkdown th {
    padding: 4px 7px;
    font-weight: normal;
    border: 1px solid #ddd;
}

.amymarkdown table {
    padding: 4px 7px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
}

.amymarkdown img {
    max-width: 100%;
}

.darkmode a {
    color: #5dc0ff !important;
}

.amymarkdown h6 {
    margin-top: 7px;
}

.amymarkdown h5 {
    margin-top: 7px;
}
.amymarkdown h4 {
    margin-top: 7px;
}
.amymarkdown h3 {
    margin-top: 7px;
}
.amymarkdown h2 {
    margin-top: 7px;
}
.amymarkdown h1 {
    margin-top: 7px;
}

.amymarkdown pre > code {
    width: 100%;
    display: block;
    padding: 20px 15px;
    margin-bottom: 30px;
}
.amymarkdown code {
    background-color: #161616;
    display: inline-block;
    color: white;
    padding: 5px 6px;
    border-radius: 10px;
    margin: -5px 0;
}

ghost-table td {
    border: none !important;
    padding-bottom: 0.5em !important;
}

ghost-table th {
    border: none !important;
}

.noParaMargin p {
    margin: 0;
}
