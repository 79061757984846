body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.amymarkdown {
    line-height: 1.5;
}

.amymarkdown pre {
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.amymarkdown code {
    background: #eee;
    color: black;
}
.darkmode.amymarkdown code,
.darkmode .amymarkdown code {
    background-color: #161616;
    color: white;
}

.amymarkdown h1 {
    font-size: 36pt;
}
.amymarkdown h2 {
    font-size: 30pt;
}
.amymarkdown h3 {
    font-size: 24pt;
}
.amymarkdown h4 {
    font-size: 18pt;
}
.amymarkdown h5 {
    font-size: 14pt;
}
.amymarkdown h6 {
    font-size: 12pt;
}
